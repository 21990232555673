import React from 'react';
import { APIProvider, ControlPosition, Map, MapControl } from '@vis.gl/react-google-maps';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import styles from './LocationPicker.module.sass';
import { UndoRedoControl, useDrawingManager } from 'components/map';
import { getErrorMessage } from '@core';
import Card from 'components/Card';
import TextInput from 'components/TextInput';

const LocationContent: React.FC<{ defaultCoordinates: string | null }> = ({ defaultCoordinates }) => {
  const drawingManager = useDrawingManager();

  const {
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const handleLocationUpdate = (polygon: string) => {
    // console.log(polygon, 'polygon');
    setValue('checkinMapPolygon', polygon, { shouldValidate: true });
  };

  const errorMessage = getErrorMessage(errors, 'checkinMapPolygon');

  return (
    <Card
      className={cn(styles.card)}
      title="დაჩექინების არეალი"
      classTitle="title-green"
      head={errorMessage && <div className={styles.error}>{errorMessage}</div>}
    >
      <>
        <TextInput
          className={styles.field}
          label="ლოკაციის სახელი"
          name="locationName"
          error={getErrorMessage(errors, 'locationName')}
          type="text"
          register={register}
        />

        <br />

        <Map
          defaultZoom={16}
          style={{ height: '600px', width: '100%' }}
          defaultCenter={{ lat: 41.723018, lng: 44.789877 }}
          gestureHandling={'greedy'}
          disableDefaultUI
        />

        <MapControl position={ControlPosition.TOP_CENTER}>
          <UndoRedoControl
            savedCoordinates={defaultCoordinates}
            locationUpdated={handleLocationUpdate}
            drawingManager={drawingManager}
          />
        </MapControl>
      </>
    </Card>
  );
};

const LocationPicker: React.FC<{ defaultCoordinates: string | null }> = ({ defaultCoordinates }) => {
  const API_KEY = process.env.GOOGLE_MAPS_API_KEY as string;

  return (
    <APIProvider apiKey={API_KEY}>
      <LocationContent defaultCoordinates={defaultCoordinates} />
    </APIProvider>
  );
};

export default LocationPicker;
