import React, { useEffect, useReducer, useRef } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

import reducer, { useDrawingManagerEvents, useOverlaySnapshots } from './undo-redo';

interface Props {
  drawingManager: google.maps.drawing.DrawingManager | null;
  locationUpdated: (polygon: string) => void;
  savedCoordinates: string | null;
}

export const UndoRedoControl = ({ drawingManager, savedCoordinates, locationUpdated }: Props) => {
  console.log(savedCoordinates, 'savedCoordinates');
  const map = useMap();

  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    const coordinates = state.now?.snapshot?.path?.map(latLng => ({
      lat: latLng.lat(),
      lng: latLng.lng(),
    }));

    if (coordinates) {
      locationUpdated(JSON.stringify(coordinates));
    }
  }, [state]);

  const overlaysShouldUpdateRef = useRef<boolean>(false);

  useDrawingManagerEvents(drawingManager, overlaysShouldUpdateRef, dispatch, savedCoordinates);
  useOverlaySnapshots(map, state, overlaysShouldUpdateRef);

  return <></>;
};
