import { EventCreatePayload, IEventListItem, IPaginatedResponse, IResponse } from '@types';
import { httpClient } from '../http-client';

export const getEventList = async (): Promise<IPaginatedResponse<IEventListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<IEventListItem>>('event/list', {
    params: {
      page: 1,
      limit: 10,
    },
  });
  return response.data;
};

export const getEvent = async (id: string): Promise<IResponse<IEventListItem>> => {
  const response = await httpClient.get<IResponse<IEventListItem>>('event', {
    params: {
      id,
    },
  });
  return response.data;
};

export const createEvent = async (payload: EventCreatePayload): Promise<IEventListItem> => {
  const response = await httpClient.post<IEventListItem>('event/create', payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const updateEvent = async (id: string, payload: EventCreatePayload): Promise<IEventListItem> => {
  const response = await httpClient.put<IEventListItem>(`event/${id}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

export const deleteEvent = async (id: string): Promise<IResponse<{ id: string; success: boolean }>> => {
  const response = await httpClient.delete<IResponse<{ id: string; success: boolean }>>('event/delete', {
    data: { id },
  });

  return response.data;
};
