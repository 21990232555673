/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Axios, { AxiosError, AxiosRequestHeaders } from 'axios';

interface IHttpServiceConfig {
  baseUrl: string;
  urlsWithoutJWT?: string[];
}

let activeJWT: string = '';
let serviceConfig: IHttpServiceConfig = {} as IHttpServiceConfig;

export const httpClient = Axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const getJWT = (): string => {
  return activeJWT;
};
export const changeJWT = (newJwt: string) => {
  activeJWT = newJwt;
};

export const isRequiredJWT = (requestUrl?: string): boolean => {
  return !serviceConfig.urlsWithoutJWT?.some(url => requestUrl?.startsWith(url));
};

export const getPathname = (baseUrl?: string, url?: string) => {
  return new URL((baseUrl || location.origin) + (url || ''), location.origin).pathname;
};

export const updateConfiguration = (config: Pick<IHttpServiceConfig, 'baseUrl' | 'urlsWithoutJWT'>) => {
  serviceConfig.baseUrl = config.baseUrl;
  serviceConfig.urlsWithoutJWT = config.urlsWithoutJWT;
};

export const setUpHttpClientConfig = (config: IHttpServiceConfig): void => {
  serviceConfig = { ...config };

  httpClient.interceptors.request.use(async request => {
    request.baseURL = request.baseURL || serviceConfig.baseUrl;
    request.headers = { ...request.headers } as AxiosRequestHeaders;

    if (isRequiredJWT(request.url) && activeJWT) {
      request.headers.Authorization = `Bearer ${activeJWT}`;
    }

    return request;
  });

  httpClient.interceptors.response.use(
    response => {
      return response;
    },
    async (error: AxiosError) => {
      // handle error globaly
      const unauthorizedCode = 401;
      if (error.response?.status === unauthorizedCode && isRequiredJWT(error?.config?.url)) {
        try {
          window.sessionStorage.setItem('nextRoute', location.pathname);
        } catch (e) {}
        // The best approach here is to keep the previous URL lang+country, but for the sake of code simplicity it
        // can be omitted because users anyway will be redirected to his previous lang+country settings

        //TODO: uncomment
        //location.href = paths.SIGNIN_PATH;
      }
      throw error;
    },
  );
};
