import { Route, Routes } from 'react-router-dom';

import { useRoutePaths } from 'hooks';
import { PrivateRoute } from '../PrivateRoute';
import { PublicRoute } from '../PublicRoute';
import Auth from 'pages/auth/auth';
import Home from 'pages/home/home';
import UserListPage from 'pages/users';
import EventListPage from 'pages/events/list';
import ModifyEventPage from 'pages/events/modify';
import RankingPage from 'pages/ranking';

function Router() {
  const {
    SIGNIN_PATH,
    REGISTER_PATH,
    ROOT_PATH,
    USER_LIST_PATH,
    EVENTS_LIST_PATH,
    EVENTS_MODIFY_PATH,
    RANKING_LIST_PATH,
    EVENTS_ADD_PATH,
  } = useRoutePaths();

  return (
    <Routes>
      <Route path={ROOT_PATH} element={<PrivateRoute redirectTo={SIGNIN_PATH} />}>
        <Route element={<Home />} index />
        <Route path={USER_LIST_PATH} element={<UserListPage />} />
        <Route path={RANKING_LIST_PATH} element={<RankingPage />} />
        <Route path={EVENTS_ADD_PATH} element={<ModifyEventPage />} />
        <Route path={EVENTS_MODIFY_PATH} element={<ModifyEventPage />} />
        <Route path={EVENTS_LIST_PATH} element={<EventListPage />} />
      </Route>

      <Route
        path={SIGNIN_PATH}
        element={
          <PublicRoute>
            <Auth />
          </PublicRoute>
        }
      />

      <Route path={REGISTER_PATH} element={<Auth />} />

      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  );
}

export default Router;
