const ROOT_PATH = '/';
const SIGNIN_PATH = '/login';
const REGISTER_PATH = '/register';
const USER_LIST_PATH = '/users';
const RANKING_LIST_PATH = '/ranking';
const EVENTS_LIST_PATH = '/events';
const EVENTS_ADD_PATH = '/events/add';
const EVENTS_MODIFY_PATH = '/events/modify/:id';

const paths = {
  ROOT_PATH,
  SIGNIN_PATH,
  REGISTER_PATH,
  USER_LIST_PATH,
  EVENTS_LIST_PATH,
  RANKING_LIST_PATH,
  EVENTS_ADD_PATH,
  EVENTS_MODIFY_PATH,
} as const;

export default paths;
