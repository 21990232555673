import { IPaginatedResponse, IUserListItem } from '@types';
import { httpClient } from '../http-client';

export const getUserList = async (): Promise<IPaginatedResponse<IUserListItem>> => {
  const response = await httpClient.get<IPaginatedResponse<IUserListItem>>('user/list', {
    params: {
      page: 1,
      limit: 10,
    },
  });
  return response.data;
};
