// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JXCv5LibX_SVJMB8trag {
  color: #e70000;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/events/modify/Location-Picker/LocationPicker.module.sass"],"names":[],"mappings":"AACA;EACI,cAAA;EACA,eAAA;AAAJ","sourcesContent":["\n.error\n    color: #e70000\n    font-size: 12px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `JXCv5LibX_SVJMB8trag`
};
export default ___CSS_LOADER_EXPORT___;
