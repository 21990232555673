import React, { useState } from 'react';
import cn from 'classnames';
import DatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';

import styles from './Dates.module.sass';
import Card from 'components/Card';
import { getErrorMessage } from '@core';

const Dates = () => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const updateDate = (date: Date, name: string) => {
    console.log(date, name, 'date');
    setValue(name, date, { shouldValidate: true });
  };

  return (
    <Card className={cn(styles.card)} title="თარიღები" classTitle="title-green">
      <div className={cn(styles.wrapper)}>
        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            თარიღი
            {getErrorMessage(errors, 'date') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'date')}</span>
            )}
          </div>

          <DatePicker
            selected={getValues('date')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
            onChange={date => updateDate(date, 'date')}
          />
        </div>

        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            ბილეთის მოთხოვნის ბოლო
            {getErrorMessage(errors, 'requestTicketDeadlineDate') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'requestTicketDeadlineDate')}</span>
            )}
          </div>
          <DatePicker
            selected={getValues('requestTicketDeadlineDate')}
            onChange={date => updateDate(date, 'requestTicketDeadlineDate')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
          />
        </div>

        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            დაჩექინკების დაწყება
            {getErrorMessage(errors, 'checkInStartDate') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'checkInStartDate')}</span>
            )}
          </div>
          <DatePicker
            selected={getValues('checkInStartDate')}
            onChange={date => updateDate(date, 'checkInStartDate')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
          />
        </div>

        <div className={cn(styles.date)}>
          <div className={cn(styles.label)}>
            დაჩექინკების დასრულება
            {getErrorMessage(errors, 'checkInEndDate') && (
              <span className={cn(styles.error)}>- {getErrorMessage(errors, 'checkInEndDate')}</span>
            )}
          </div>
          <DatePicker
            selected={getValues('checkInEndDate')}
            onChange={date => updateDate(date, 'checkInEndDate')}
            showTimeSelect
            locale="ka"
            timeFormat="HH:mm"
            dateFormat={'dd/M/yyyy HH:mm'}
          />
        </div>
      </div>
    </Card>
  );
};

export default Dates;
