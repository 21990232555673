import React, { useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import styles from './GeneralInformation.module.sass';
import Card from 'components/Card';
import Icon from 'components/Icon';
import TextInput from 'components/TextInput';
import Editor from 'components/Editor';
import File from 'components/File';
import { paths } from 'router/paths';
import { getErrorMessage } from '@core';

interface GeneralInformationProps {
  className?: string;
}

const GeneralInformation: React.FC<GeneralInformationProps> = ({ className }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [defaultContent, setDefaultContent] = React.useState('');

  const [imageControlValue, setImageControlValue] = React.useState();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === undefined) {
        setDefaultContent(value.description);
      }
      handleImageChange(value.image);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleContentChange = (value: string) => {
    setValue('description', value, { shouldValidate: true });
  };

  const handleImageChange = (value: any) => {
    if (value?.[0]?.name) {
      const currentFile = value?.[0];
      if (currentFile) {
        setImageControlValue(URL.createObjectURL(currentFile));
      }
    } else if (typeof value === 'string') {
      setImageControlValue(value);
    }
  };

  return (
    <Card
      className={cn(styles.card, className)}
      title="ზოგადი ინფორმაცია"
      classTitle="title-green"
      head={
        <Link className={cn('button-stroke button-small', styles.button)} to={paths.EVENTS_LIST_PATH}>
          <Icon name="arrow-left" size="24" />
          <span>უკან</span>
        </Link>
      }
    >
      <div className={styles.description}>
        <File
          error={getErrorMessage(errors, 'image')}
          className={styles.field}
          name="image"
          title="ფოტოს ატვირთვა"
          type="image"
          label="ფოტო"
          value={imageControlValue}
          register={register}
        />
        <TextInput
          className={styles.field}
          label="სახელი"
          name="title"
          error={getErrorMessage(errors, 'title')}
          type="text"
          register={register}
        />
        <Editor
          classEditor={styles.editor}
          label="აღწერა"
          onChange={handleContentChange}
          defaultValue={defaultContent}
          error={getErrorMessage(errors, 'description')}
        />
      </div>
    </Card>
  );
};

export default GeneralInformation;
