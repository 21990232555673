import React, { forwardRef } from 'react';
import cn from 'classnames';
import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';

import styles from './TextInput.module.sass';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

interface TextInputProps {
  className?: string;
  classLabel?: string;
  classInput?: string;
  label?: string;
  icon?: string;
  copy?: boolean;
  currency?: string;
  tooltip?: string;
  place?: string;
  register?: UseFormRegister<FieldValues>;
  name: string;
  type: string;
  error?: string;
}

export type Ref = HTMLInputElement;

const TextInput: React.FC<TextInputProps> = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  currency,
  tooltip,
  place,
  register,
  error,
  name,
  type,
}) => {
  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        { [styles.error]: error },
        className,
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{' '}
          {tooltip && (
            <Tooltip className={styles.tooltip} title={tooltip} icon="info" place={place ? place : 'right'} />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <input className={cn(classInput, styles.input)} type={type} name={name} {...(register && register(name))} />
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{' '}
          </div>
        )}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size="24" />{' '}
          </button>
        )}
        {currency && <div className={styles.currency}>{currency}</div>}

        {error && (
          <>
            <button className={cn(styles.copy, 'error-message-icon')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                  d="M12 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1zm7.675 2.504L12.87 5.563a1 1 0 0 0-1.738 0L4.327 17.504A1 1 0 0 0 5.196 19h13.61a1 1 0 0 0 .869-1.495zM14.607 4.573c-1.151-2.019-4.062-2.019-5.213 0L2.589 16.514C1.45 18.514 2.894 21 5.196 21h13.61c2.302 0 3.746-2.486 2.606-4.485L14.607 4.573z"
                  fill-rule="evenodd"
                  fill="#ff6a55"
                />
                <path d="M13 17a1 1 0 1 1-2 0 1 1 0 1 1 2 0z" fill="#ff6a55" />
              </svg>
            </button>
            <Tooltip className={'.error-message-icon'} place={'bottom-end'} type="error">
              <span>{error}</span>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
};

export default TextInput;
