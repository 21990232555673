import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import moment from 'moment';

import styles from './NewProduct.module.sass';
import GeneralInformation from './GeneralInformation';
import Panel from './Panel';
import { createEvent, getEvent, isValidImageType, LONG_DATE_FORMAT, updateEvent } from '@core';

import { OverlayLoader } from 'components/Loader';
import Dates from './Dates';
import { EventCreatePayload } from '@types';
import LocationPicker from './Location-Picker';

const schema = yup.object().shape({
  title: yup.string().required('სავალდებულოა'),
  description: yup.string().required('სავალდებულოა'),
  date: yup.date().required('სავალდებულოა'),
  checkInStartDate: yup.date().required('სავალდებულოა'),
  checkInEndDate: yup.date().required('სავალდებულოა'),
  requestTicketDeadlineDate: yup.date().required('სავალდებულოა'),
  locationName: yup.string().required('სავალდებულოა'),
  checkinMapPolygon: yup.string().required('სავალდებულოა'),
  image: yup
    .mixed()
    .required('ველი სავალდებულოა')
    .test('is-valid-image-type', 'სავალდებულოა', value => {
      return isValidImageType(value);
    }),
});

type FormTypes = 'add' | 'edit';

const ModifyEvent = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [formType, setFormType] = React.useState<FormTypes>('add');
  const formMethods = useForm<EventCreatePayload>({ resolver: yupResolver(schema) });
  const [eventIsAdding, setEventIsAdding] = React.useState(false);

  const [savedCoordinates, setSavedCoordinates] = React.useState<string | null>(null);

  const onSubmit = () => {
    void formMethods.handleSubmit(
      data => {
        setEventIsAdding(true);

        let requestData = {
          ...data,
        };
        if (data.image[0] instanceof File) {
          requestData = {
            ...data,
            image: data.image[0],
          };
        }

        const promise = formType === 'edit' ? updateEvent(params.id, requestData) : createEvent(requestData);

        promise
          .then(() => {
            toast.success('წარმატებით დაემატა');
            navigate('/events');
          })
          .catch(() => {
            toast.error('დაფისქირდა შეცდომა');
          })
          .finally(() => {
            setEventIsAdding(false);
          });
      },
      (errors, event) => {
        console.log(errors, event, 'validation errors');
      },
    )();
  };

  useEffect(() => {
    if (params.id) {
      setFormType('edit');
      void getEvent(params.id).then(response => {
        setSavedCoordinates(response.data.checkinMapPolygon);
        formMethods.reset({
          title: response.data.title,
          description: response.data.description,
          date: moment(response.data.date, LONG_DATE_FORMAT).toDate(),
          checkInStartDate: moment(response.data.checkInStartDate, LONG_DATE_FORMAT).toDate(),
          checkInEndDate: moment(response.data.checkInEndDate, LONG_DATE_FORMAT).toDate(),
          requestTicketDeadlineDate: moment(response.data.requestTicketDeadlineDate, LONG_DATE_FORMAT).toDate(),
          image: response.data.imageUrl,
          locationName: response.data.locationName,
        });
      });
    }
  }, [params.id]);

  return (
    <>
      <FormProvider {...formMethods}>
        {eventIsAdding && <OverlayLoader />}
        <>
          <div className={styles.row}>
            <div className={styles.col}>
              <GeneralInformation className={styles.card} />
              <LocationPicker defaultCoordinates={savedCoordinates} />
              <Dates className={styles.card} />

              {/* <Price className={styles.card} /> */}
            </div>
          </div>
          <Panel onSubmit={onSubmit} />
        </>
      </FormProvider>
    </>
  );
};

export default ModifyEvent;
